<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/room" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  let typeList = ['1001', '1002', '1003', '1004', '1005']
                  if (typeList.indexOf(this.currNode.type) > -1) {
                    let { data } = this.formOptions
                    data["parentId"] = this.currNode.id;
                    data["communityId"] = this.currNode.communityId || this.currNode.id;
                    data["parentType"] = this.currNode.type;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区、楼栋、单元或房间')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "设备名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              maxlength: 24,
            },
          },
          {
            field: "equpClass",
            title: "设备类别",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "facilitiesType",
              props: {
                placeholder: "请选择设备类别",
              },
            },
          },
          {
            field: "depreciationValue",
            title: "折旧值",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入折旧值",
              },
            },
          },
          {
            field: "netValue",
            title: "净值(移交价值)",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入净值(移交价值)",
              },
            },
          },
          {
            field: "originalTime",
            title: "移交单位原值",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入移交单位原值",
              },
            },
          },
          {
            field: "entryTime",
            title: "入账时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择入账时间",
              }
            },
          },
          {
            field: "monthlyDiscount",
            title: "月折扣",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入月折扣",
              },
            },
          },
          {
            field: "reDepreciationPeriod",
            title: "接收后折旧年限",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入月折扣",
              },
            },
          },
          {
            field: "reResidualValue",
            title: "接收后残值率",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入接收后残值率",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 130,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  let typeList = ['1001', '1002', '1003', '1004', '1005']
                  if (typeList.indexOf(this.currNode.type) > -1) {
                    data["communityId"] = node.communityId || node.id;
                    data["parentId"] = node.id;
                    data["parentType"] = node.type;
                  } else {
                    this.$message.error('请选择小区、楼栋、单元或房间')
                  }
                },
              }
            },
          },
          {
            field: "name",
            title: "设备名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入设备名称",
              },
            },
          },
          {
            field: "equpClass",
            title: "设备类别",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "facilitiesType",
              props: {
                placeholder: "请选择设备类别",
              },
            },
          },
          {
            field: "childGeneration",
            title: "批量创建",
            span: 10,
            visibleMethod({ data, property }) {
              if (data["parentType"] == "1004") {
                data[property] = "0"
              }
              if (!data.parentType || data.parentType == "1004") {
                data[property] = "0"
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: '$radio',
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否批量创建",
              },
            },
          },
          {
            title: "备注",
            visibleMethod({ data }) {
              if (!data.parentType || data.parentType == "1004") {
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: '$button',
              props: {
                content: "是否为每个房间创建当前设备",
                type: "text",
                status: "danger",
                maxlength: 24,
                placeholder: "请选择是否批量生成",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "其他信息",
                position: "left"
              }
            },
          },
          {
            field: "depreciationValue",
            title: "折旧值",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入折旧值",
              },
            },
          },
          {
            field: "netValue",
            title: "净值(移交价值)",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入净值(移交价值)",
              },
            },
          },
          {
            field: "originalTime",
            title: "移交单位原值",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入移交单位原值",
              },
            },
          },
          {
            field: "entryTime",
            title: "入账时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择入账时间",
              }
            },
          },
          {
            field: "monthlyDiscount",
            title: "月折扣",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入月折扣",
              },
            },
          },
          {
            field: "reDepreciationPeriod",
            title: "接收后折旧年限",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入接收后折旧年限",
              },
            },
          },

          {
            field: "reResidualValue",
            title: "接收后残值率",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入接收后残值率",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          // communityName: [
          //     { required: true, message: "请输入小区名称" },
          //     { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          // ],
          name: [{ required: true, message: "请输入设备名称" }],
          equpClass: [{ required: true, message: "请选择设备类别" }],
          childGeneration: [{ required: true, message: "请选择是否批量创建" }],
          // netValue: [{ required: true, message: "净值(移交价值)" }],
          // status: [{ required: true, message: "请选择状态" }],
          parentId: [{ required: true, message: "请选择所属公司" }],
        },
        // 新增功能字段初始化
        data: {
          childGeneration: "0",
          entryTime: "",
          status: "1",
          enabled: "1",
          type: '1006',
          communityId: '',
          parentId: '',
          equpClass: '1'
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "设备名称",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入设备名称",
              },
            },
          },
          {
            field: "equpClass",
            title: "设备类别",
            itemRender: {
              name: "$select",
              optionTypeCode: "facilitiesType",
              props: {
                placeholder: "请选择设备类别",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          equpClass: '',
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      let { data } = this.searchOptions;
      data["parentType"] = node.type;
      data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
